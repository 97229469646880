import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w03MM03Gitarre = () => (
  <Layout>
    <SEO title="w03MM03Gitarre" />

    <h1 id="ueberschrift">Musik machen</h1>
    <h2>Gitarre für Anfänger &emsp;  ©&nbsp;1989</h2>

<p className="note">!!!DIESER KURS WIRD AKTUELL AUSGESETZT!!!</p>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w03MM03Gitarre&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p className="poem">
        "Keine Frau schläft so fest, daß der Klang einer Gitarre sie nicht ans Fenster locken würde."<br />
        <div style={{fontSize: "0.75em"}}>aus Spanien</div>
      </p>

    <p>---</p>

      <p>
        ... und es ein unvergleichbares Erlebnis mit Freunden und einer (oder mehreren) Gitarre
        um ein Lagerfeuer zu sitzen, ein paar Kartoffeln oder Stockbrote oder "Mäusespeck" (heute: Marshmallows)
        ins Feuer zu halten und fröhlich (sowie lauthals) Lieder zu den Sternen zu schicken. 
      </p>

      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Mitmachkurs
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Sitzplatz pro Teilnehmer + mind. 1 Sitzplatz extra<br/>
        (inklusive genügend Platz zur Seite)<br/>
        Materialbedarf: + ggf. Gitarre +
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 2 Sitzplätze und etwas Freiraum
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w03MM03Gitarre
